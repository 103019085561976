import React from "react";
import "./timeline.css";
export default function Timeline() {
  return (
    <div class="timeline">
      <div class="container left">
        <div class="content">
          <h2>2022</h2>
          <p>
            Ruby on Rails Developer at Danphe Software Labs.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2021</h2>
          <p>Software Engineer at CloudChomp, Inc. </p>
        </div>
      </div>
      <div class="container left">
        <div class="content">
          <h2>2020</h2>
          <p>Web developer at App discovery lab</p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2019</h2>
          <p>Finished BE in Electronics & Communication</p>
        </div>
      </div>
    </div>
  );
}
