import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes'
import './App.css'
ReactDOM.render(
  <React.StrictMode>
     {Routes}
  </React.StrictMode>,
  document.getElementById('root')
)
