import React from 'react'
import './Slogan-styles.css'
export default function Slogan() {
    return (
        <div class="aboutme css-typing">
            <p className="hi">
                Hi, my name is
            </p>
            <p className="name">
                Niraj Budhathoki.
            </p>
            <p className="name">
                I build things for web.
            </p>
        </div>
    )
}
