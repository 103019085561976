import React from 'react'
import './intro.css'
export default function Intro() {
    return (
        <div className="container-card">
           <p className="intro-p" >
                👨🛠📋  I'm an aspiring engineer and self-taught programmer. I'm always fascinated by solving problems through technology maybe that brought me to choose this carrier path.
           </p>
            <p className="intro-p" >
               
                👨‍💻 I like programming in general. Currently persuing javascript to tinker the web. I have experience  and worked on javascript frameworks like Node.js and React.js
           </p>
            <p className="intro-p" >
                🏕️   My hobbies include travelling and hiking outdoors.
           </p>
        </div>
    )
}
